import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { bannerSubtitleAnimation } from "../../settings/generalAnimations";
import PageHeader from "../../components/PageHeader";

const BuilderBanner = ({ title, subtitle, pageHeader }) => {
	const headerOnBanner = pageHeader?.position === 'on';
	const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';

	const titleBelowBannerMarkup = headerBelowBanner && pageHeader?.title
		? <PageHeader {...pageHeader} isGeneral={true} />
		: null;
	const bannerTitleProps = headerOnBanner && pageHeader?.title
		? pageHeader
		: {};

	const bannerTitleMarkup = (
		<PageHeader
			{...bannerTitleProps}
			title={headerOnBanner && pageHeader?.title ? pageHeader?.title : title}
			implemented={true}
			className={"section-title section-title--large builder-banner__title"}
			isGeneral={headerOnBanner}
		/>
	);

	return (
		<>
			{headerOnBanner || title || subtitle
				? (
					<section className="builder-banner">
						<div className="container">
							{bannerTitleMarkup}

							{subtitle && (
								<motion.p
									className="section-subtitle builder-banner__subtitle"
									{...bannerSubtitleAnimation}
								>
									{subtitle}
								</motion.p>
							)}
						</div>
					</section>
				)
				: null
			}

			{titleBelowBannerMarkup}
		</>
	);
};

BuilderBanner.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string
};

export default BuilderBanner;
