import React from "react"
import PropTypes from "prop-types";

const BuilderCta = ({ text }) => {
	if (!text) {
		return null;
	}

	return (
		<div className="builder-cta">
			<div className="container container--medium">
				<div className="builder-cta__content">
					<h2 className="builder-cta__text">
						{text}
					</h2>
				</div>
			</div>
		</div>
	);
};

BuilderCta.propTypes = {
	text: PropTypes.string
};

export default BuilderCta;