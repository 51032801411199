import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";

import BuilderBanner from "../sections/Builder/BuilderBanner";
import BuilderSummary from "../sections/Builder/BuilderSummary";
import BuilderInfo from "../sections/Builder/BuilderInfo";
import BuilderCta from "../sections/Builder/BuilderCta";
import BuilderArticle from "../sections/Builder/BuilderArticle";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const Builder = ({ pageContext }) => {
	const {
		title,
		banner,
		builderSummary,
		usage,
		article,
		features,
		ctaText,
		technologies,
		metaData,
		pageHeader,
	} = pageContext;

	const pageTitle = metaData?.title || title;
	const pageDescription = metaData?.description || "UTD builder";

	useBeaconWidget(beaconWidgetIds.GENERAL);

	return (
		<DefaultLayout>
			<DefaultHelmet title={pageTitle} description={pageDescription}/>

			<BuilderBanner
				{...banner}
				pageHeader={pageHeader}
			/>

			<BuilderSummary
				{...builderSummary}
				technologies={technologies}
			/>

			<BuilderInfo
				{...features}
			/>

			<BuilderCta
				text={ctaText}
			/>

			<BuilderArticle
				text={usage}
			/>

			<BuilderArticle
				text={article}
			/>
		</DefaultLayout>
	);
}

export default Builder;
