import React from "react"
import PropTypes from "prop-types";

import BuilderTabs from "../../components/Builder/BuilderTabs";
import BuilderLinks from "../../components/Builder/BuilderLinks";

const BuilderInfo = ({ features, links }) => {
	if (!features.length || !links.length) {
		return null;
	}

	return (
		<div className="builder-info">
			<div className="container container--medium builder-info__container">
				<div className="builder-info__row">
					{features.length > 0 &&
						<div className="builder-info__col builder-info__col--tabs">
							<BuilderTabs
								features={features}
							/>
						</div>
					}

					{links.length > 0 &&
						<div className="builder-info__col builder-info__col--links">
							<BuilderLinks
								links={links}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

BuilderInfo.propTypes = {
	features: PropTypes.array,
	links: PropTypes.array
};

export default BuilderInfo;