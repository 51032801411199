import React, {createRef, forwardRef, useState} from "react"
import PropTypes from "prop-types";
import classNames from "classnames";
import {fadeIn, fadeOut} from "../../helpers/fade";

const Button = ({ activeTab, title, id, changeActiveTab }) => {
	const buttonClasses = classNames({
		"builder-tabs__button": true,
		"builder-tabs__button--active": activeTab === id
	});

	return (
		<button className={buttonClasses} type="button" onClick={() => changeActiveTab(id)}>
			<span className="builder-tabs__button-title">
				{title}
			</span>
		</button>
	);
};

const Content = forwardRef(({ content, id, activeTab }, ref) => {
	const tabClasses = classNames({
		"builder-tabs__content": true,
		"builder-tabs__content--active": activeTab === id
	});

	return (
		<div ref={ref} className={tabClasses}>
			<div className="builder-tabs__content-row">
				{content.map(({ title, text }, index) => {
					if (!title && !text) {
						return null;
					}

					return (
						<div
							key={index}
							className="builder-tabs__content-col"
						>
							<h4 className="builder-tabs__content-col-title">
								{title}
							</h4>

							<p className="builder-tabs__content-col-text">
								{text}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
});

const BuilderTabs = ({ features }) => {
	const [activeTab, setActiveTab] = useState(0);
	const [references, setReferences] = useState({});
	const [loading, setLoading] = useState(false);

	const getOrCreateRef = (id) => {
		if (!references.hasOwnProperty(id)) {
			setReferences({
				...references,
				[id]: createRef()
			})
		}

		return references[id];
	}

	const changeActiveTab = (id) => {
		if(activeTab === id || loading) {
			return null;
		}

		setLoading(true);

		setActiveTab(id);

		fadeOut(references[activeTab].current, 300, () => {
			fadeIn(references[id].current, 300, () => {
				setLoading(false);
			});
		});
	};

	const tabs 		= [];
	const contents 	= [];

	features.forEach((feature, index) => {
		tabs.push(
			<Button
				key={index}
				id={index}
				changeActiveTab={changeActiveTab}
				activeTab={activeTab}
				title={feature.tabTitle}
			/>
		);

		contents.push(
			<Content
				key={index}
				id={index}
				ref={getOrCreateRef(index)}
				content={feature.tabContent}
				activeTab={activeTab}
			/>
		);
	});

	return (
		<div className="builder-tabs">
			<div className="builder-tabs__buttons">
				{tabs}
			</div>

			<div className="builder-tabs__contents">
				{contents}
			</div>
		</div>
	);
};

BuilderTabs.propTypes = {
	features: PropTypes.array.isRequired
};

export default BuilderTabs;