import React from "react";
import PropTypes from "prop-types";

const BuilderArticle = ({ text }) => {
	if (!text) {
		return null;
	}

	return (
		<section className="builder-article">
			<div className="container container--small">
				<div
					className="rte builder-article__rte"
					dangerouslySetInnerHTML={{ __html: text }}
				/>
			</div>
		</section>
	);
};

BuilderArticle.propTypes = {
	text: PropTypes.string
};

export default BuilderArticle;