export function fadeIn(element, ms, callback = () => {}, errorCallback = () => {}) {
	if(!element) {
		errorCallback();
		return null;
	}

	element.style.opacity = 0;
	element.style.display = "inline-block";
	element.style.visibility = "visible";

	if(!ms) {
		element.style.opacity = 1;

		return null;
	}

	let opacity = 0;
	let timer = setInterval(() => {
		opacity += 40 / ms;

		if(opacity >= 1) {
			clearInterval(timer);
			opacity = 1;

			callback();
		}

		element.style.opacity = opacity;
	}, 40);
}

export function fadeOut(element, ms, callback = () => {}) {
	if(!element) {
		return null;
	}

	if(!ms) {
		element.style.opacity = 0;
		element.style.display = "none";
		element.style.visibility = "hidden";

		return null;
	}

	let opacity = 1;
	let timer = setInterval(() => {
		opacity -= 40 / ms;

		if(opacity <= 0) {
			clearInterval(timer);
			opacity = 0;
			element.style.display = "none";
			element.style.visibility = "hidden";
			callback();
		}

		element.style.opacity = opacity;
	}, 40);
}
