import React from "react";
import PropTypes from "prop-types";

const BuilderSummary = ({ title, description, backgroundColor, year, siteUrl, service, technologies }) => {
	const services = service.map(service => service.service);
	technologies = technologies.filter(technology => services.includes(technology.slug));

	return (
		<section
			className="project-summary"
			style={{
				"--section-background-color": backgroundColor
			}}
		>
			<div className="container container--medium project-summary__wrapper">
				<div className="project-summary__columns">
					<div className="project-summary__column">
						<h2 className="project-summary__title medium-title">
							{title}
						</h2>

						<p className="project-summary__description">
							{description}
						</p>
					</div>

					<div className="project-summary__column">
						<div className="project-summary__table">
							<div className="project-summary__table-row">
								<div className="project-summary__table-column project-summary__table-column--name">
									Year
								</div>

								<div className="project-summary__table-column project-summary__table-column--value">
									{year}
								</div>
							</div>

							{Boolean(technologies.length) && (
								<div className="project-summary__table-row">
									<div className="project-summary__table-column project-summary__table-column--name">
										Services
									</div>

									<div className="project-summary__table-column project-summary__table-column--value">
										{technologies.map((({ name, id, acfTechnology }) => {
											const icon = acfTechnology?.icon;

											return (
												<div key={id} className="project-summary__service">
													{icon && (
														<div className="icon-wrapper" aria-hidden="true" dangerouslySetInnerHTML={{ __html: icon }} />
													)}

													{name}
												</div>
											);
										}))}
									</div>
								</div>
							)}

							{Boolean(siteUrl.length) && (
								<div className="project-summary__table-row">
									<div className="project-summary__table-column project-summary__table-column--name">
										Download
									</div>

									<div className="project-summary__table-column project-summary__table-column--value">
										{siteUrl.map((({ url }, index) => {
											return (
												<p key={index}>
													<a
														className="project-summary__site-link"
														href={url}
														target="_blank"
														rel="noreferrer"
														aria-label={`${url}. Link will be opened in a new tab.`}
													>
														{url}
													</a>
												</p>
											);
										}))}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

BuilderSummary.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	backgroundColor: PropTypes.string.isRequired,
	year: PropTypes.string.isRequired,
	siteUrl: PropTypes.array.isRequired,
	technologies: PropTypes.array
};

export default BuilderSummary;