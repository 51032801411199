import React from "react"
import PropTypes from "prop-types";
import classNames from "classnames";

const BuilderLinks = ({ links }) => {
	return (
		<div className="builder-links">
			{links.map(({ title, content }, i) => {
				return (
					<div
						key={i}
						className="builder-links__item"
					>
						{title &&
							<h3 className="builder-links__item-title">
								{title}
							</h3>
						}

						{content.map(({ icon, link, linkTitle }, j) => {
							const builderLinkClasses = classNames({
								"builder-links__item-link": true,
								"builder-links__item-link--underline": !icon
							})

							return (
								<div
									key={j}
									className="builder-links__item-link-wrapper"
								>
									<a
										className={builderLinkClasses}
										href={link}
										target="_blank"
										rel="noreferrer"
										aria-label={`${linkTitle ? linkTitle : link}. Link will be opened in a new tab.`}
									>
										{icon &&
											<div
												className="builder-links__item-link-icon"
												dangerouslySetInnerHTML={{ __html: icon }}
											/>
										}

										{linkTitle}

										{!icon && !linkTitle && link}
									</a>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

BuilderLinks.propTypes = {
	links: PropTypes.array.isRequired
};

export default BuilderLinks;
